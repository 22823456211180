import * as React from "react";

import { Flex, Text, Stack, VStack } from "@chakra-ui/react";

import oil from "../images/oil-min.jpg";

const Header = () => {
  return (
    <>
      <Flex
        w={"full"}
        backgroundImage={oil}
        backgroundSize={"cover"}
        backgroundPosition={"0 -7rem"}
        backgroundRepeat="no-repeat"
        backgroundAttachment="fixed"
      >
        <VStack
          w={"full"}
          justify={"center"}
          bgGradient={"linear(to-b, blackAlpha.700, blackAlpha.600)"}
          py={{ base: "8rem", md: "12rem", xl: "14rem" }}
        >
          <Stack maxW={"8xl"} align={"center"}>
            <Text
              color={"white"}
              fontWeight={800}
              lineHeight={1.2}
              fontSize={{ base: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
              align="center"
              mt={{ base: 16, xl: 0 }}
            >
              OLEJE, DOPLNKY A DIELY <Text>NA OBJEDNÁVKU</Text>
            </Text>
          </Stack>
        </VStack>
      </Flex>
    </>
  );
};

export default Header;
