import * as React from "react";

import { Flex, Text, Box, SimpleGrid } from "@chakra-ui/react";

import { GiSmartphone } from "react-icons/gi";
import { FiGrid } from "react-icons/fi";
import { RiTruckLine } from "react-icons/ri";

const About = () => {
  return (
    <>
      <Flex
        px={{
          base: "24px",
          md: "48px",
          lg: "96px",
          "2xl": "256px",
        }}
        py={10}
        bg="white"
        borderBottom="1px"
        borderColor="blackAlpha.300"
        transition="all 250ms"
        justify="center"
        flexWrap="wrap"
      >
        <SimpleGrid columns={{ base: "1", md: "3" }} spacing={16}>
          <Flex align="center" justify="start">
            <Box
              fontSize={{ base: "48", md: "36", xl: "48" }}
              p={{ base: "4", md: "3", xl: "4" }}
              borderRadius="full"
              mr={4}
              color="red.500"
              bg="red.50"
            >
              <GiSmartphone />
            </Box>
            <Box>
              <Text fontSize="lg" fontWeight={700}>
                Objednávka cez telefón
              </Text>
              <Text>Stačí si vybrať a zavolať</Text>
            </Box>
          </Flex>
          <Flex align="center" justify="start">
            <Box
              fontSize={{ base: "48", md: "36", xl: "48" }}
              p={{ base: "4", md: "3", xl: "4" }}
              borderRadius="full"
              mr={4}
              color="red.500"
              bg="red.50"
            >
              <FiGrid />
            </Box>
            <Box>
              <Text fontSize="lg" fontWeight={700}>
                Široký sortiment
              </Text>
              <Text>U nás najdete všetko to, čo potrebujete</Text>
            </Box>
          </Flex>
          <Flex align="center" justify="start">
            <Box
              fontSize={{ base: "48", md: "36", xl: "48" }}
              p={{ base: "4", md: "3", xl: "4" }}
              borderRadius="full"
              mr={4}
              color="red.500"
              bg="red.50"
            >
              <RiTruckLine />
            </Box>
            <Box>
              <Text fontSize="lg" fontWeight={700}>
                Rýchle doručenie
              </Text>
              <Text>Rýchle doručenie až ku vám domov</Text>
            </Box>
          </Flex>
        </SimpleGrid>
      </Flex>
    </>
  );
};

export default About;
