import * as React from "react";

import {
  Flex,
  Button,
  Image,
  Text,
  Stack,
  Box,
  Heading,
  VStack,
} from "@chakra-ui/react";

import {
  GiCarWheel,
  GiStoneWheel,
  GiSpring,
  GiSteeringWheel,
  GiCarBattery,
} from "react-icons/gi";
import { FiChevronRight } from "react-icons/fi";
import { AiTwotoneHighlight } from "react-icons/ai";

import products from "../products.js";

import parts from "../images/parts-min.jpg";

const Parts = (props) => {
  const displayParts = products
    .filter((product) => product.type === "brzdy")
    .slice(0, 4)
    .map((product) => (
      <Flex
        p={6}
        py={8}
        w="full%"
        border="1px"
        borderColor="blackAlpha.200"
        boxShadow={"lg"}
        _hover={{ boxShadow: "dark-lg", cursor: "pointer" }}
        rounded={"lg"}
        flexWrap="wrap"
        justify="center"
        transition="all 250ms"
        key={product.id}
      >
        <Image
          rounded={"lg"}
          height={60}
          width="100%"
          objectFit="contain"
          src={product.image}
        />
        <Box>
          <Stack pt={7} align={"center"}>
            <Text
              color={"black.500"}
              fontSize={"sm"}
              textTransform={"uppercase"}
              align="center"
            >
              {product.brand}
            </Text>
            <Heading
              fontSize={"2xl"}
              fontFamily={"body"}
              fontWeight={500}
              align="center"
            >
              {product.name}
            </Heading>
            <Stack direction={"row"} align={"center"}>
              <Text fontWeight={800} fontSize={"xl"}>
                {product.price}€
              </Text>
              <Text textDecoration={"line-through"} color={"gray.600"}>
                {props.formatter.format(product.price + product.price / 3)}€
              </Text>
            </Stack>
          </Stack>
        </Box>
      </Flex>
    ));

  return (
    <>
      <Flex
        w={"full"}
        backgroundImage={parts}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
        backgroundRepeat="no-repeat"
        backgroundAttachment="fixed"
      >
        <VStack
          w={"full"}
          justify={"center"}
          bgGradient={"linear(to-b, blackAlpha.900, blackAlpha.700)"}
        >
          <Box
            w={"full"}
            px={{
              base: "24px",
              md: "48px",
              lg: "96px",
              "2xl": "256px",
            }}
            transition="all 250ms"
            py={{ base: "4rem", md: "8rem", xl: "10rem" }}
          >
            {/*
            <Flex
              align="center"
              width="100%"
              borderBottom="2px"
              borderColor="red.500"
              pb={4}
              flexWrap="wrap"
              justify="space-between"
              color="white.900"
            >
              <Flex justify="start" align="center">
                <Box fontSize="4xl" mr={3} color="red.500">
                  <GiCarWheel />
                </Box>
                <Heading as="h1">Autodiely</Heading>
              </Flex>

              <Flex justify="start" align="flex-end" flexWrap="wrap" mt={3}>
                <Button variant="ghost" color="black.900">
                  Pneumatiky
                </Button>
                <Button variant="ghost" color="black.900">
                  Brzdy
                </Button>
                <Button variant="ghost" color="black.900">
                  Motor
                </Button>
                <Button variant="ghost" color="black.900">
                  Odpruženie
                </Button>
                <Button variant="ghost" color="black.900">
                  Riadenie
                </Button>
                <Button variant="ghost" color="black.900">
                  Elektronika
                </Button>

                <Button
                  variant="ghost"
                  color="red.500"
                  rightIcon={<FiChevronRight />}
                >
                  Ďalšie
                </Button>
              </Flex>
            </Flex>
          */}

            <Flex
              flexWrap="wrap"
              justifyContent="center"
              flexDirection="column"
              color="white.900"
              textAlign="center"
            >
              <Flex
                justifyContent="center"
                flexWrap="wrap"
                flexDirection={{ base: "column", sm: "row" }}
              >
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <GiCarWheel />
                  </Box>
                  Pneumatiky
                </Button>
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <GiStoneWheel />
                  </Box>
                  Brzdy
                </Button>
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <AiTwotoneHighlight />
                  </Box>
                  Motor
                </Button>
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <GiSpring />
                  </Box>
                  Odpruženie
                </Button>
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <GiSteeringWheel />
                  </Box>
                  Riadenie
                </Button>
                <Button
                  variant="ghost"
                  fontSize="lg"
                  _active={{
                    background: "none",
                    color: "red.500",
                  }}
                  _hover={{
                    background: "none",
                    color: "red.500",
                  }}
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  <Box color="red.500" mr={2}>
                    <GiCarBattery />
                  </Box>
                  Elektronika
                </Button>

                <Button
                  variant="ghost"
                  color="red.500"
                  rightIcon={<FiChevronRight />}
                  fontSize="lg"
                  _focus={{
                    outlineColor: "red.500",
                  }}
                >
                  Kontaktovať
                </Button>
              </Flex>
              <Flex justifyContent="center" py={6}>
                <Text
                  fontWeight={800}
                  lineHeight={1.2}
                  fontSize={{ base: "4xl", md: "6xl" }}
                >
                  HĽADÁTE AUTODIEL PRE VAŠE AUTO?
                </Text>
              </Flex>
              <Flex justifyContent="center">
                <Text fontWeight={600} lineHeight={1.2} fontSize="3xl">
                  Ak máte záujem o autodiel, stačí zavolať a dohodneme sa.
                </Text>
              </Flex>
            </Flex>

            {/*
            <SimpleGrid
              columns={{ base: "1", sm: "2", lg: "4" }}
              spacing={3}
              mt={4}
            >
              {displayParts}
            </SimpleGrid>
            */}
          </Box>
        </VStack>
      </Flex>
    </>
  );
};

export default Parts;
