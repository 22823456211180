import * as React from "react";

import {
  Flex,
  Button,
  Image,
  Text,
  Stack,
  Box,
  Heading,
  SimpleGrid,
} from "@chakra-ui/react";
import { FiChevronRight } from "react-icons/fi";
import { RiOilLine } from "react-icons/ri";

import products from "../products.js";

import { Link } from "gatsby";

const Oils = (props) => {
  const displayOils = products
    .filter((product) => product.type === "olej")
    .slice(0, 4)
    .map((product) => (
      <Flex
        p={6}
        py={8}
        w="full"
        border="1px"
        borderColor="blackAlpha.200"
        boxShadow={"lg"}
        _hover={{ boxShadow: "dark-lg", cursor: "pointer" }}
        rounded={"lg"}
        flexWrap="wrap"
        justify="center"
        transition="all 250ms"
        key={product.id}
      >
        <Link to={`/produkt/?id=${product.id}`}>
          <Image
            rounded={"lg"}
            height={60}
            width="100%"
            objectFit="contain"
            src={product.image}
          />
        </Link>

        <Link to={`/produkt/?id=${product.id}`}>
          <Box>
            <Stack pt={7} align={"center"}>
              <Text
                color={"black.500"}
                fontSize={"sm"}
                textTransform={"uppercase"}
                align="center"
              >
                {product.brand}
              </Text>
              <Heading
                fontSize={"2xl"}
                fontFamily={"body"}
                fontWeight={500}
                align="center"
              >
                {product.name}
              </Heading>
              <Stack direction={"row"} align={"center"}>
                <Text fontWeight={800} fontSize={"xl"}>
                  {product.price}€
                </Text>
                <Text textDecoration={"line-through"} color={"gray.600"}>
                  {props.formatter.format(product.price + product.price / 3)}€
                </Text>
              </Stack>
            </Stack>
          </Box>
        </Link>
      </Flex>
    ));

  return (
    <>
      <Box
        px={{
          base: "24px",
          md: "48px",
          lg: "96px",
          "2xl": "256px",
        }}
        bg="white"
        transition="all 250ms"
        py={{ base: 12, md: 24 }}
        borderBottom="1px"
        borderColor="blackAlpha.300"
      >
        <Flex
          align={{ base: "start", sm: "center" }}
          width="100%"
          borderBottom="1px"
          borderColor="blackAlpha.300"
          pb={6}
          flexWrap="wrap"
          justify="space-between"
          color="black.900"
          flexDirection={{ base: "column", sm: "row" }}
        >
          <Flex justify="start" align="center">
            <Box fontSize="4xl" mr={3} color="red.500">
              <RiOilLine />
            </Box>
            <Heading as="h1">Oleje</Heading>
          </Flex>

          <Flex
            justify="start"
            align={{ base: "flex-start", sm: "flex-end" }}
            flexWrap="wrap"
            flexDirection={{ base: "column", sm: "row" }}
            mt={{ base: 5, md: 3 }}
          >
            <Link to="/oleje/?subCategory=motorovy">
              <Button
                variant="ghost"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
              >
                Motorové oleje
              </Button>
            </Link>

            <Link to="/oleje/?subCategory=brzdovy">
              <Button
                variant="ghost"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
              >
                Brzdové kvapaliny
              </Button>
            </Link>

            <Link to="/oleje/?subCategory=prevodovy">
              <Button
                variant="ghost"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
              >
                Prevodové oleje
              </Button>
            </Link>

            <Link to="/oleje/?subCategory=chladiaci">
              <Button
                variant="ghost"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
              >
                Kvapaliny do chladičov
              </Button>
            </Link>

            <Link to="/oleje/?subCategory=ostrekovaci">
              <Button
                variant="ghost"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
              >
                Kvapaliny do ostrekovačov
              </Button>
            </Link>

            <Link to="/oleje">
              <Button
                variant="ghost"
                color="red.500"
                _focus={{ outlineColor: "red.500" }}
                _hover={{ backgroundColor: "red.50" }}
                _active={{ backgroundColor: "red.50" }}
                rightIcon={<FiChevronRight />}
              >
                Ďalšie
              </Button>
            </Link>
          </Flex>
        </Flex>

        <SimpleGrid
          columns={{ base: "1", sm: "2", lg: "4" }}
          spacing={4}
          mt={8}
        >
          {displayOils}
        </SimpleGrid>
      </Box>
    </>
  );
};

export default Oils;
