import * as React from "react";
import { Helmet } from "react-helmet";

import Navbar from "../components/navbar";
import Header from "../components/header";
import About from "../components/about";
import Stock from "../components/stock";
import Footer from "../components/footer";

import theme from "../theme";

import { ChakraProvider } from "@chakra-ui/react";

import GlobalStyle from "../globalStyles";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>DMS-VID - Svet pre vaše auto :: Domov</title>
      </Helmet>

      <GlobalStyle />

      <ChakraProvider theme={theme}>
        <Navbar
          home="red.500"
          oils="whiteAlpha.600"
          accessories="whiteAlpha.600"
          contact="whiteAlpha.600"
        />

        <Header />

        <About />

        <Stock />

        <Footer />
      </ChakraProvider>
    </>
  );
};

export default IndexPage;
