import * as React from "react";

import Oils from "./oils";
import Accessories from "./accessories";
import Parts from "./parts";

const Stock = (props) => {
  const formatter = new Intl.NumberFormat("sk-SK", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <Oils formatter={formatter} />
      <Accessories formatter={formatter} />
      <Parts formatter={formatter} />
    </>
  );
};

export default Stock;
